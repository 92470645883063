import React, { useState, useEffect, Fragment } from 'react';
import { Flex, Text, Button, Box } from 'rebass/styled-components';
import { navigate } from 'gatsby';
import CardSubtitle from '../components/CardSubtitle';
import Line from '../components/Line';
import ReactMarkdown from 'react-markdown';
import StyledRectangle from '../components/StyledRectangle';
import PackageInfoForm from '../components/PackageInfoForm';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { Link as GatsbyLink } from 'gatsby';
import { STORAGE_KEYS } from '../resources/constants';
import styled from 'styled-components';

const PackageSubtitle = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.31;
  letter-spacing: 0.4px;
  color: #ff5e63;
  padding: 10px 0 20px 0;
`;

const PackageDescription = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.36;
  color: #8b97a2;
  padding: 20px 0 10px 0;
`;

const PackageDetails = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.35px;
  color: #161f26;
`;

const PackageInfo = () => {
  const [data, setData] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem(STORAGE_KEYS.PACKAGE_DATA))
      navigate('/next-steps');
    else {
      let packageData = JSON.parse(
        localStorage.getItem(STORAGE_KEYS.PACKAGE_DATA)
      );
      setData(packageData.packageInfo);
    }
  }, []);

  const handleTrackCustomEvent = actionName => {
    trackCustomEvent({
      category: 'package info',
      action: actionName
    });
  };

  if (!data) return null;
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      maxWidth="550px"
      width="100%"
      mb={5}
    >
      <StyledRectangle bg="white" py={6} px={5}>
        <CardSubtitle fontSize="28px">{data.title}</CardSubtitle>
        <PackageSubtitle>{data.subtitle}</PackageSubtitle>
        <Line height="3px" color="red" my={1} />
        <PackageDescription>
          <ReactMarkdown source={data.description.description} />
        </PackageDescription>
        <PackageDetails>
          <ReactMarkdown source={data.details.details} />
        </PackageDetails>
        <Flex justifyContent="center" alignItems="center" mt={10}>
          <GatsbyLink
            to={
              data.button.urlInternal
                ? data.button.urlInternal
                : `${data.button.url}?userid=${localStorage.getItem(
                    STORAGE_KEYS.ID
                  )}`
            }
            target={data.button.urlInternal ? '' : '_blank'}
          >
            <Button
              variant="bDarkRed"
              onClick={() => handleTrackCustomEvent(data.button.text)}
            >
              {data.button.text}
            </Button>
          </GatsbyLink>
        </Flex>
      </StyledRectangle>

      <Box my={5} width={1}>
        <PackageInfoForm
          data={data.form}
          button={data.backButton}
          handleTrackCustomEvent={handleTrackCustomEvent}
        />
      </Box>
    </Flex>
  );
};

export default PackageInfo;
