import React from 'react';
import Layout from '../components/Layout';
import PackageInfo from '../sections/PackageInfo';

const PackageInfoPage = () => (
  <Layout logoHeader={true}>
    <PackageInfo />
  </Layout>
);

export default PackageInfoPage;
