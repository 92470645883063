import React, { Fragment, useState } from 'react';
import { Input } from '@rebass/forms';
import { Flex, Text, Box, Button, Image } from 'rebass/styled-components';
import styled from 'styled-components';
import StyledRectangle from '../components/StyledRectangle';
import CardSubtitle from '../components/CardSubtitle';
import MessageForm from '../components/MessageForm';
import { Link as GatsbyLink } from 'gatsby';
import { RequestInfoPackageService } from '../services/services';
import { STORAGE_KEYS } from '../resources/constants';
import InputMask from 'react-input-mask';
import InputBox from '../components/InputBox';

import animationCircuit from '../../assets/images/animation_circuit.gif';

const StyledSubtext = styled(Text)`
    font-size: 14px;
    font-weight: 500;
    line-height: 1.36;
    color: #8b97a2;
    padding: 15px 0 30px 0;
  }
`;

const PackageInfoForm = ({ data, button, handleTrackCustomEvent }) => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [messageSent, setMessageSent] = useState(false);
  const [sending, setSending] = useState(false);

  console.log(data);

  const handleSubmit = event => {
    event.preventDefault();

    const data = {
      email: email,
      phoneNumber: phone
    };

    let packageData = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.PACKAGE_DATA)
    );

    RequestInfoPackageService(data, packageData.contentful_id)
      .then(response => {
        if (response.status == '200') {
          setMessageSent(true);
          setSending(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Fragment>
      <StyledRectangle bg="white" py={6} px={5}>
        <Box as="form" id="PackageInfoForm" onSubmit={handleSubmit}>
          {!messageSent && (
            <Fragment>
              <CardSubtitle fontSize="18px">{data.title}</CardSubtitle>
              <StyledSubtext>{data.description}</StyledSubtext>
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                {sending && (
                  <Flex
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center"
                  >
                    <Image src={animationCircuit} alt="animation-circuit" />
                  </Flex>
                )}
                {!sending && (
                  <Fragment>
                    <Flex width={1}>
                      <InputBox
                        label="Email"
                        placeholder="Enter email address"
                        value={email}
                        setValue={setEmail}
                        required="required"
                        type="email"
                      />
                    </Flex>
                    <Flex mt={5} width={1}>
                      <InputBox
                        label="Phone number"
                        placeholder="Enter phone number"
                        value={phone}
                        setValue={setPhone}
                        required="required"
                        type="number"
                      />
                    </Flex>
                    <Button
                      variant="bDarkRed"
                      height="50px"
                      width="170px"
                      my={6}
                      type="submit"
                      onClick={() => handleTrackCustomEvent(data.button.text)}
                    >
                      Submit
                    </Button>
                  </Fragment>
                )}
              </Flex>
            </Fragment>
          )}
          {messageSent && (
            <Box>
              <MessageForm data={data} />
            </Box>
          )}
        </Box>
      </StyledRectangle>
      {messageSent && (
        <Box my={5}>
          <Flex justifyContent="center" alignItems="center" mt={10}>
            <GatsbyLink
              to={
                button.urlInternal
                  ? button.urlInternal
                  : `${button.url}?userid=${localStorage.getItem(
                      STORAGE_KEYS.ID
                    )}`
              }
              target={button.urlInternal ? '' : '_blank'}
            >
              <Button
                variant="blightGrey"
                onClick={() => handleTrackCustomEvent(button.text)}
              >
                {button.text}
              </Button>
            </GatsbyLink>
          </Flex>
        </Box>
      )}
    </Fragment>
  );
};

export default PackageInfoForm;
