import React from 'react';
import { Flex, Image, Text } from 'rebass/styled-components';
import styled from 'styled-components';

const MessageTitle = styled(Text)`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.08;
  color: #0f131b;
`;

const MessageDescription = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
  color: #8b97a2;
  padding: 15px 0 26px 0;
`;

const Message = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.31;
  letter-spacing: 0.4px;
  color: #ff5e63;
`;

const MessageForm = ({ data }) => {
  return (
    <Flex flexDirection="column" width={1} py={5}>
      <MessageTitle>{data.messageTitle}</MessageTitle>
      <MessageDescription>{data.messageDescription}</MessageDescription>
      <Flex alignItems="center" justifyContent="center">
        <Image
          src={data.messageIcon.file.url}
          alt={data.messageIcon.file.fileName}
        ></Image>
        <Message>{data.message}</Message>
      </Flex>
    </Flex>
  );
};

export default MessageForm;
